/*
 * @Author: co
 * @Date: 2021-07-14 11:06:46
 */

import request from '@/plugins/request';
import qs from 'qs'
let baseUrlString = '/giantfind-website/api/website'
// 获取市区
export function getAreaList(data) {
	return request({
		url: `${baseUrlString}/sysArea/getAreaList`,
		method: 'get',
		params: data,
	});
}

// 获取地铁线路
export function rentTrafficLine(data) {
	return request({
		url: `${baseUrlString}/rentTrafficLine/queryByCityId`,
		method: 'get',
		params: data,
	});
}

// 获取地铁站台
export function rentTrafficLinePlatform(data) {
	return request({
		url: `${baseUrlString}/rentTrafficLinePlatform/getPageListByLineId`,
		method: 'get',
		params: data,
	});
}