/*
 * @Author: co
 * @Date: 2021-06-30 10:12:40
 */
import * as util from '@/libs/util';
import router from '@/router';
import {
	listFindRoomBySQL, listFindRoomCountByPoint, getRentTrafficLinePlatform, getRentTrafficLine, listRentTagInit
} from '@api/FindRoom';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
		Entities: cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listFindRoomBySQL({ state }) {
			return new Promise((resolve, reject) => {
				listFindRoomBySQL(state.AdvanceQuery)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
