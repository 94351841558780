import axios from 'axios';
import * as util from '@/libs/util';
import Setting from '@/setting';

axios.defaults.crossDomain = true
axios.defaults.withCredentials = true

// 创建一个 axios 实例
const service = axios.create({
  baseURL: Setting.apiBaseURL,
  timeout: 360000 // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
  config => {
    config.headers['content-type'] = 'application/json'
    return config
  },
  error => Promise.reject(error)
);

// 响应拦截器
service.interceptors.response.use(
  response => {
    // dataAxios 是 axios 返回数据中的 data
    const dataAxios = response.data;
    // 这个状态码是和后端约定的
    const {
      respCode
    } = dataAxios;
    // 根据 respCode 进行判断
    if (respCode === undefined) {
      // 如果没有 respCode 代表这不是项目后端开发的接口
      return dataAxios;
    } else {
      // 有 respCode 代表这是一个后端接口 可以进行进一步的判断
      switch (respCode) {
        case '0000':
          // [ 示例 ] respCode === 0000 代表没有错误
          if (dataAxios.overview && dataAxios.overview != '成功') {
            // Message.success({
            // 	content: `${dataAxios.overview}`,
            // 	duration: Setting.modalDuration
            // });
            console.log(`${dataAxios.overview}`)
          }
          return dataAxios.data;
      }
    }
  },
  error => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = '请求错误';
          break;
        case 401:
          error.message = '未授权，请登录';
          break;
        case 403:
          error.message = '拒绝访问';
          break;
        case 404:
          error.message = `请求地址出错: ${error.response.config.url}`;
          break;
        case 408:
          error.message = '请求超时';
          break;
        case 500:
          error.message = '服务器内部错误';
          break;
        case 501:
          error.message = '服务未实现';
          break;
        case 502:
          error.message = '网关错误';
          break;
        case 503:
          error.message = '服务不可用';
          break;
        case 504:
          error.message = '网关超时';
          break;
        case 505:
          error.message = 'HTTP版本不受支持';
          break;
        default:
          break;
      }
    }
    return Promise.reject(error);
  }
);

export default service;
