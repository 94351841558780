import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import VueLazyLoad from 'vue-lazyload'
import {VueJsonp} from 'vue-jsonp'

import {initSQL, initPhoneSQL} from '@/plugins/utils/initsqllist'
import App from '@/App.vue'
import Toast from '@/components/common/toast/toast'
import router from '@/router'
import store from '@/store'



Vue.use(Toast)
Vue.use(VueJsonp)
// 配置项
Vue.use(VueLazyLoad, {
  error: require('@/assets/images/banner/error_banner.png'),
  loading: require('@/assets/images/icon/loading_icon.gif'),
  attempt: 2,
  preLoad: 2.3,
})
Vue.prototype.$initSQL = initSQL
Vue.prototype.$initPhoneSQL = initPhoneSQL

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
