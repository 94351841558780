/*
 * @Author: co
 * @Date: 2021-06-30 10:12:40
 */
import * as util from '@/libs/util';
import router from '@/router';
import {
	getAreaList, rentTrafficLine, rentTrafficLinePlatform
} from '@api/Area';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
		Entities: cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		getAreaList({ state }, obj) {
			return new Promise((resolve, reject) => {
				getAreaList(obj)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		// 获取地铁线路
		rentTrafficLine({ state }, obj) {
			return new Promise((resolve, reject) => {
				rentTrafficLine(obj)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		// 获取地铁站台
		rentTrafficLinePlatform({ state }, obj) {
			return new Promise((resolve, reject) => {
				rentTrafficLinePlatform(obj)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
