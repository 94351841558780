/*
 * @Author: co
 * @Date: 2021-06-30 10:12:40
 */
// 初始化官网房源列表查询条件
export function initSQL() {
    let obj = {
        streetIds: [],
        areaId: '',
        cityId: '',
        commutingMode: '',
        commutingTime: '',
        contractTime: '',
        endPrice: '',
        keyWord: '',
        houseAddress: '',
        houseOrientations: [],
        businessAreaIDList: [],
        latitude: '',
        lineId: '',
        lineName: '',
        linePlatfromIdList: [],
        longitude: '',
        lastReformProduceLine: '',
        patternRoom: '',
        projectType: '',
        rentTagIdList: [],
        startPrice: '',
        orderColumn: "",
        pageNo: 1,
        pageSize: 30,
    }
    return obj
}

export function initPhoneSQL() {
    let obj = {
        streetIds: [],
        areaId: '',
        cityId: '',
        commutingMode: '',
        commutingTime: '',
        contractTime: '',
        endPrice: '',
        keyWord: '',
        houseAddress: '',
        houseOrientations: [],
        businessAreaIDList: [],
        latitude: '',
        lineId: '',
        lineName: '',
        linePlatfromIdList: [],
        longitude: '',
        lastReformProduceLine: '',
        patternRoom: '',
        projectType: '',
        rentTagIdList: [],
        startPrice: '',
        orderColumn: "",
        pageNo: 1,
        pageSize: 10,
    }
    return obj
}