/*
 * @Author: your name
 * @Date: 2021-04-07 14:30:15
 * @LastEditTime: 2021-08-20 18:07:40
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \giant_find\iview-admin-pro-template\src\api\rentmanage\FindRoom.js
 */
/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-06-16T19:11:22.048
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 */
import request from '@/plugins/request';
import qs from 'qs'
export function listFindRoomBySQL(data) {
	return request({
		url: '/rentmanage/api/rentHouseBig/listFindRoomBySQL',
		method: 'post',
		data: data,
	});
}

export function listFindRoomCountByPoint(data) {
	return request({
		url: '/rentmanage/api/rentHouseBig/listFindRoomCountByPoint',
		method: 'post',
		data: data,
	});
}
// 根据城市获取地铁线路
export function getRentTrafficLine(data) {
	return request({
		url: '/rentmanage/api/rentTrafficLine/getRentTrafficLine',
		method: 'get',
		params: data,
	});
}
// 根据地铁线路获取站台
export function getRentTrafficLinePlatform(data) {
	return request({
		url: '/rentmanage/api/rentTrafficLinePlatform/getRentTrafficLinePlatform',
		method: 'get',
		params: data,
	});
}

// 获取房源标签
export function listRentTagInit(data) {
	return request({
		url: '/rentmanage/api/rentTag/listRentTagInit',
		method: 'post',
		data: data,
	});
}
