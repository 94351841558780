/*
 * @Author: co
 * @Date: 2021-07-14 11:06:46
 */

import request from '@/plugins/request';
let baseUrlString = '/giantfind-website/api/website'
// 预约看房-发送短信
export function sms(data) {
	return request({
		url: `${baseUrlString}/sys/sms`,
		method: 'post',
		data: data,
	});
}

// 预约看到-提交
export function saveRentTakeLookRecord(data) {
	return request({
		url: `${baseUrlString}/sys/saveRentTakeLookRecord`,
		method: 'post',
		data: data,
	});
}
