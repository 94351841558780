let pre = 'pc-'
const frameIn = [
  {
    path: '/',
    name: `${pre}index`,
    meta: {
      title: '建方长租官网 - 广州租房信息 - 广州品牌公寓'
    },
    component: () => import('@/views/pc/index')
  },
  {
    path: '/rent-house',
    name: `${pre}rent-house`,
    meta: {
      title: '房源列表'
    },
    component: () => import('@/views/pc/rent-house')
  },
  {
    path: '/detail-home/:id',
    name: `${pre}detail-home`,
    meta: {
      title: '建方家详情'
    },
    component: () => import('@/views/pc/detail-home')
  },
  {
    path: '/detail-apartment/:id',
    name: `${pre}detail-apartment`,
    meta: {
      title: '建方寓详情'
    },
    component: () => import('@/views/pc/detail-apartment')
  },
  {
    path: '/gf-home',
    name: `${pre}gf-home`,
    meta: {
      title: '建方家'
    },
    component: () => import('@/views/pc/gf-home')
  },
  {
    path: '/gf-apartment',
    name: `${pre}gf-apartment`,
    meta: {
      title: '建方寓'
    },
    component: () => import('@/views/pc/gf-apartment')
  },
  {
    path: '/about-gf',
    name: `${pre}about-gf`,
    meta: {
      title: '关于建方'
    },
    component: () => import('@/views/pc/about-gf')
  },
  {
    path: '/all-news',
    name: `${pre}all-news`,
    meta: {
      title: '全部新闻'
    },
    component: () => import('@/views/pc/all-news')
  },
  {
    path: '/detail-news',
    name: `${pre}detail-news`,
    meta: {
      title: '新闻详情'
    },
    component: () => import('@/views/pc/detail-news')
  },
  {
    path: '/map-find-house',
    name: `${pre}map-find-house`,
    meta: {
      title: '地图找房'
    },
    component: () => import('@/views/pc/map-find-house')
  },
  {
    path: '/owner-sign',
    name: `${pre}owner-sign`,
    meta: {
      title: '业主签约'
    },
    component: () => import('@/views/pc/owner-sign')
  }
]

// 重新组织后导出
export default [
  ...frameIn,
  {
    path: '/403',
    name: '403',
    meta: {
      title: '403'
    },
    component: () => import('@/views/system/error/403')
  },
  {
    path: '/500',
    name: '500',
    meta: {
      title: '500'
    },
    component: () => import('@/views/system/error/500')
  },
  {
    path: '/404',
    name: '404',
    meta: {
      title: '404'
    },
    component: () => import('@/views/system/error/404')
  },
  {path: '*', redirect: {name: '404'}}
]
