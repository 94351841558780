/*
 * @Author: co
 * @Date: 2021-07-14 11:06:46
 */

import request from '@/plugins/request';

let baseUrlString = '/giantfind-website/api/website'
// 获取房源特色
export function rentTag(data) {
	return request({
		url: `${baseUrlString}/rentTag/pageList`,
		method: 'get',
		params: data,
	});
}

// 获取房源列表
export function getPageList(data) {
	return request({
		url: `${baseUrlString}/rentHouse/getPageList`,
		method: 'post',
		data: data,
	});
}

// 房屋详情-家
export function getHouseDetail(data) {
	return request({
		url: `${baseUrlString}/rentHouse/getHouseDetail`,
		method: 'get',
		params: data,
	});
}


// 房屋详情-公寓
export const getRentHouseApartmentDetail = (params) => request.get(`${baseUrlString}/store/queryById`, {params})

// 房屋详情-推荐列表
export const getPageListRecommend = (params) => request.post(`${baseUrlString}/rentHouse/getPageList`, params)

// 房屋-寓列表
export const getRentHouseApartmentPageList = (params) => request.get(`${baseUrlString}/store/getPageList`, {params})


//  地图找房
export function listFindRoomCountByPoint(data) {
	return request({
		url: `${baseUrlString}/rentHouse/listFindRoomCountByPoint`,
		method: 'post',
		data: data,
	});
}
