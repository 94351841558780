/*
 * @Author: co
 * @Date: 2021-06-30 10:12:40
 */
import {
	rentTag,
	getPageList,
	getHouseDetail,
	getRentHouseApartmentPageList,
	listFindRoomCountByPoint
} from '@api/HouseIntro';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
		Entities: cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		rentTag({ state }, obj) {
			return new Promise((resolve, reject) => {
				rentTag(obj)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getHouseDetail({ state }, obj) {
			return new Promise((resolve, reject) => {
				getHouseDetail(obj)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listFindRoomCountByPoint({ state }, obj) {
			return new Promise((resolve, reject) => {
				listFindRoomCountByPoint(obj)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getPageList({ state }) {
			return new Promise((resolve, reject) => {
				getPageList(state.AdvanceQuery.entity)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentHouseApartmentPageList({ state }, obj) {
			return new Promise((resolve, reject) => {
				console.log(obj)
				getRentHouseApartmentPageList(obj)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


	}
};
