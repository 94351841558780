/*
 * @Descripttion: 
 * @version: 
 * @Author: co
 * @Date: 2021-04-07 14:30:16
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-02 15:28:12
 * @FilePath: \giant_find\iview-admin-pro-template\src\plugins\utils\RespCommonDef.js
 */
const RespCommonDef = {
	//查询条件
	AdvanceQuery: {
		entity: {
			streetIds: [],
			areaId: '',
			cityId: '',
			commutingMode: '',
			commutingTime: '',
			contractTime: '',
			endPrice: '',
			keyWord: '',
			houseAddress: '',
			houseOrientations: [],
			businessAreaIDList: [],
			latitude: '',
			lineId: '',
			lineName: '',
			linePlatfromIdList: [],
			longitude: '',
			lastReformProduceLine: '',
			patternRoom: '',
			projectType: '',
			rentTagIdList: [],
			startPrice: '',
			orderColumn: "",
			pageNo: 1,
			pageSize: 30,
		},
	},
	//新增或编辑初使化返回
	CompoundEntityDictionary: {
		configItems: [],
		configRanges: [],
		configTrees: [],
		pageFunctionAuthorizations: [],//功能权限
		entityResponse: []
	},
	//列表初使化接口返回
	CompoundEntitiesDictionary: {
		configItems: [],
		configRanges: [],
		configTrees: [],
		pageFunctionAuthorizations: [{
			rangeAuthorizations: []
		}], //功能权限
		rowFunctionAuthorization: [],
		entities: [],
	},
	//列表接口返回
	CompoundEntities: {
		rowFunctionAuthorization: [],
		pageFunctionAuthorizations: [],
		entities: [{
			entities: [],
			total: 0
		}],
	},
	Entities: {
		entities: [],
		total: 0
	},
	CompoundEntity: {
		pageFunctionAuthorizations: [],
		entityResponse: []
	},
	CompoundEntityDictionary: {
		configItems: [],
		configRanges: [],
		configTrees: [],
		pageFunctionAuthorizations: [],
		entityResponse: []
	},
	Count: {
		effectCount: 0
	},
	Entity: {
		entity: [],
		isEffect: false
	},
	EntityCount: {
		entity: [],
		isEffect: false,
		effectCount: 0
	},
	Row: {
		row: [],
		isEffect: false
	},
	RowAuthorization: {
		rowId: [],
		accessCodes: []
	},
	Rows: {
		total: 0,
		rows: []
	}

};

export default RespCommonDef;
