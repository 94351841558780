import Setting from '@/setting';

/**
 * @description 更改标题
 * @param {Object} title 标题
 * @param {Object} count 未读消息数提示（可视情况选择使用或不使用）
 */
export function title({title, count}) {
  let fullTitle = title ? `${title}${Setting.titleSuffix}` : Setting.titleSuffix;

  if (count) fullTitle = `(${count}条消息)${fullTitle}`;
  window.document.title = fullTitle;
};

export function requestAnimation(task) {
  if ('requestAnimationFrame' in window) return window.requestAnimationFrame(task);
  setTimeout(task, 16);
}
