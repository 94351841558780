/*
 * @Author: co
 * @Date: 2021-06-30 10:12:40
 */
import * as util from '@/libs/util';
const state = {
	CityList: [],
	SelectCity: {},
	SearchKeyword: ''
}

const mutations = {
	setCityList: (state, data) => {
		state.CityList = data
	},
	setCity: (state, data) => {
		state.SelectCity = data
	},
	setSearchKeyword: (state, data) => {
		state.SearchKeyword = data
	},
}

const actions = {
	setCityList: ({ commit }, data) => {
		commit('setCityList', data)
	},
	setCity: ({ commit }, data) => {
		commit('setCity', data)
	},
	setSearchKeyword: ({ commit }, data) => {
		commit('setSearchKeyword', data)
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
