import Vue from 'vue'
import Vuex from 'vuex'

import pc from './modules/pc'

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		pc
	},
})
