<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
  import {mapState, mapActions} from 'vuex'

  export default {
    name: 'App',
    computed: mapState('pc/publicSet/index', ['SelectCity']),
    methods: {
      ...mapActions('pc/publicSet/index', ['setCity', 'setCityList']),
      ...mapActions('pc/area/index', ['getAreaList']),
      // 城市列表
      async getAreaListFn() {
        try {
          const {result} = await this.getAreaList({parentId: 44, areaLevel: 1})

          this.setCityList(result)
          this.setCity(result[0])
        } catch (e) {}
      }
    },
    mounted () {
      this.getAreaListFn()
    },
  }
</script>

<style lang="less">
  @import '@/styles/index.less';

  html,
  body {
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    user-select: none;
    -webkit-overflow-scrolling: touch;
  }

  #app {
    color: #2c3e50;
  }

  #nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }
</style>
