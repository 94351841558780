import Vue from 'vue';
import VueRouter from 'vue-router'
import * as util from '@/libs/util'
import Setting from '@/setting'
// 路由数据
import routes from './routes'

Vue.use(VueRouter)
export const router = new VueRouter({
  routes,
  base: process.env.BASE_URL,
  mode: Setting.routerMode,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition && to.meta.cache) return savedPosition
    return {x: 0, y: 0}
  }
})

router.beforeEach((to, from, next) => {
  // 更改标题
  if (to.meta.title) util.title({title: to.meta.title})
  next()
})
router.afterEach(to => {})
export default router

